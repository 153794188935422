import React from "react";
import "../css/status.css";

function Status() {
  return (
    <div id="body" className="status-page-body">
      <h1 className="status-page-title">System Status</h1>
      <div className="status-badge">
        <iframe
          src="https://beprecise.betteruptime.com/badge?theme=dark"
          width="190"
          height="30"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </div>

      <div className="status-page-container">
        <iframe
          className="status-page-iframe"
          src="https://beprecise.betteruptime.com"
          title="System Status"
        ></iframe>
      </div>
    </div>
  );
}

export default Status;
