// NotFound.js
import React from "react";
import "../css/404.css"; // Ensure the CSS file is correctly referenced

function Maintanance() {
  return (
    <div id="body404">
      <div id="container404">
        <h1>We are under maintanance</h1>
        <p>We're sorry, but the page you're looking for is under maintanance</p>
        <p>
          Return to <a href="/">Homepage</a>
        </p>
      </div>
    </div>
  );
}

export default Maintanance;
