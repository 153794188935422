import Logoimg from "../assets/Logo.png";

function Logo() {
  return (
    <div className="logo">
      <a href="/" style={{ display: "flex" }}>
        <img src={Logoimg} style={{ maxHeight: "50px" }} alt="logo" />
        <h1
          style={{
            fontSize: "1rem",
            marginTop: "10px",
            fontWeight: "bolder",
            color: "white",
          }}
        >
          BePrecise
        </h1>
      </a>
    </div>
  );
}

export default Logo;
