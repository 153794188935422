function Links() {
  return (
    <div>
      <h1>Links</h1>
      <a href="https://discord.gg/bFSh2mBPzw">Discord</a>
      <br />
      <a href="https://github.com/BePrecise">Github</a>
      <br />
      <a href="https://x.com/BePreciseeu">Twitter</a>
    </div>
  );
}

export default Links;
