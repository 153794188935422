function QuickAccess() {
  return (
    <div>
      <h1>Quick Access</h1>
      <a href="/" id="homebtn">
        Home
      </a>{" "}
      <br />
      <a href="/status" id="statusbtn">
        Status
      </a>{" "}
      <br />
      <a href="/patchnotes" id="patchnotes">
        Patch Notes
      </a>
    </div>
  );
}

export default QuickAccess;
