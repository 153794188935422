import homeboxcss from "../css/HomeboxesV2.css";
import TicketCommendIMG from "../assets/TicketCommand.png";
import ReviewCommandIMG from "../assets/ReviewCommand.png";
import ServerStatsIMG from "../assets/ServerStatsCommand.png";

function HomeBoxesV2() {
  return (
    <div className="container">
      <div className="ContainerBox ContainerBox-Left">
        <div className="BoxIMG">
          <img src={ReviewCommandIMG} alt="discord bot" />
        </div>
        <div className="text-content">
          <h1 className="Boxh1">/review Command</h1>
          <p className="BoxP">
            The <code>/review</code> command allows users to provide feedback
            and rate things easily. When you use /review, you can write a
            comment and give a rating from 1 to 5 stars. This command helps
            gather opinions and suggestions from your community. It’s a
            straightforward way to get valuable input and see what people think
            about your service or product.
          </p>
          <a
            className="BoxA"
            href="https://discord.com/oauth2/authorize?client_id=1262102986696556554"
          >
            Invite Beprecise Bot →
          </a>
        </div>
      </div>

      <div className="ContainerBox ContainerBox-Right">
        <div className="BoxIMG">
          <img src={ServerStatsIMG} alt="discord bot" />
        </div>
        <div className="text-content">
          <h1 className="Boxh1">/serverstats Command</h1>
          <p className="BoxP">
            The <code>/serverstats</code> command provides a comprehensive
            overview of your server's performance. It displays key metrics like
            member count, active users, and server activity, helping you monitor
            your community’s growth and engagement. Use this command to stay
            informed about your server’s health and make better management
            decisions.
          </p>
          <a
            className="BoxA"
            href="https://discord.com/oauth2/authorize?client_id=1262102986696556554"
          >
            Invite Beprecise Bot →
          </a>
        </div>
      </div>

      <div className="ContainerBox ContainerBox-Left">
        <div className="BoxIMG">
          <img src={TicketCommendIMG} alt="discord bot" />
        </div>
        <div className="text-content">
          <h1 className="Boxh1">/ticket Command</h1>
          <p className="BoxP">
            The <code>/ticket create </code> command allows you to easily create
            support tickets in a Discord server. Choose a category like General
            Inquiry, Technical Support, or Billing, and set the priority to Low,
            Medium, or High. Once your ticket is created, you'll receive a
            confirmation message with a button to close the ticket when your
            issue is resolved.
          </p>
          <a
            className="BoxA"
            href="https://discord.com/oauth2/authorize?client_id=1262102986696556554"
          >
            Invite Beprecise Bot →
          </a>
        </div>
      </div>
    </div>
  );
}

export default HomeBoxesV2;
