import React from "react";
import QuickAccess from "./QuickAccess";
import Links from "./Links";
import Legal from "./Legal";
import footer from "../css/Footer.css";
import Logo from "./Logo.js";

function Footer() {
  return (
    <footer>
      <div id="container">
        <div id="grid">
          <div class="quick-access">
            <QuickAccess />
          </div>
          <div class="links">
            <Links />
          </div>
          <div class="legal">
            <Legal />
          </div>
        </div>
        <div id="right-side">
          <Logo />
          <p
            id="right-side-text"
            style={{ fontSize: "small", fontWeight: "lighter" }}
          >
            ©2024 BePrecise | All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
