import React from "react";
import DiscordInviteBanner from "../components/DiscordInviteBanner";
import HomeBoxes from "../components/HomeBoxes";
import GreenEnergy from "../components/GreenEnergy";
import HomeBoxesV2 from "../components/HomeboxesV2";

function Home() {
  // Define styles for the separator
  const separatorStyle = {
    borderBottom: "1px solid #333", // Dark grey color
    marginBottom: "1rem",
    paddingBottom: "1rem",
    margin: "0 25vh 0 25vh",
  };

  return (
    <div id="body">
      <div style={separatorStyle} />
      <DiscordInviteBanner />
      <div style={separatorStyle} />
      <HomeBoxesV2 />
      <div style={separatorStyle} />
      <GreenEnergy />
    </div>
  );
}

export default Home;
