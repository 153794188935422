import termscss from "../css/Terms.css";

function Terms() {
  return (
    <div id="body" className="terms" style={{ marginBottom: "10vh" }}>
      <h1 className="terms-h1h2">Terms and Conditions</h1>
      <p>
        Welcome to BePrecise! These terms and conditions outline the rules and
        regulations for the use of BePrecise's website and services.
      </p>

      <h2 className="terms-h1h2">1. Introduction</h2>
      <p>
        By accessing this website and engaging with our services, we assume you
        accept these terms and conditions in full. Do not continue to use
        BePrecise's website if you do not accept all the terms and conditions
        stated on this page.
      </p>

      <h2 className="terms-h1h2">2. Definitions</h2>
      <p>
        <strong>Company</strong>: Refers to BePrecise.
        <br />
        <strong>Client</strong>: Refers to the individual or entity using the
        Company’s services.
        <br />
        <strong>Services</strong>: Refers to software development, consulting,
        and other related services provided by the Company.
        <br />
        <strong>Website</strong>: Refers to BePrecise’s website.
        <br />
        <strong>Agreement</strong>: Refers to these terms and conditions.
      </p>

      <h2 className="terms-h1h2">3. Services</h2>
      <p>
        BePrecise provides software development services which include but are
        not limited to custom software development, consulting, maintenance, and
        support.
      </p>

      <h2 className="terms-h1h2">4. Use of the Website</h2>
      <p>
        By accessing the Website, you warrant and represent that you are at
        least 18 years of age and that you are legally capable of entering into
        binding contracts.
      </p>

      <h2 className="terms-h1h2">5. Intellectual Property Rights</h2>
      <p>
        Unless otherwise stated, BePrecise owns the intellectual property rights
        for all material on the Website. All intellectual property rights are
        reserved. You may view and/or print pages from the Website for your own
        personal use subject to restrictions set in these terms and conditions.
      </p>
      <p>You must not:</p>
      <ul className="termslistitem">
        <li>Republish material from the Website.</li>
        <li>Sell, rent, or sub-license material from the Website.</li>
        <li>Reproduce, duplicate, or copy material from the Website.</li>
        <li>
          Redistribute content from BePrecise (unless content is specifically
          made for redistribution).
        </li>
      </ul>

      <h2 className="terms-h1h2">6. Service Terms</h2>
      <p>
        Clients will receive a proposal outlining the scope of work, timeline,
        and payment terms. Acceptance of the proposal constitutes agreement to
        the terms contained therein.
      </p>

      <h2 className="terms-h1h2">7. Payments</h2>
      <p>
        Invoices are due upon receipt unless otherwise stated in the proposal.
        Late payments may result in interest charges or suspension of services
        until payment is received.
      </p>

      <h2 className="terms-h1h2">8. Confidentiality</h2>
      <p>
        Both parties agree to keep all confidential information received from
        the other party private and not to disclose it to any third party
        without the prior written consent of the disclosing party.
      </p>

      <h2 className="terms-h1h2">9. Limitation of Liability</h2>
      <p>
        BePrecise will not be liable for any indirect, incidental, special,
        consequential, or punitive damages, or any loss of profits or revenues,
        whether incurred directly or indirectly, or any loss of data, use,
        goodwill, or other intangible losses, resulting from:
      </p>
      <ul className="termslistitem">
        <li>Your use or inability to use the Services.</li>
        <li>Any unauthorized access or use of our servers.</li>
        <li>
          Any interruption or cessation of transmission to or from our services.
        </li>
        <li>
          Any bugs, viruses, trojan horses, or the like that may be transmitted
          to or through our services by any third party.
        </li>
        <li>
          Any errors or omissions in any content or for any loss or damage
          incurred as a result of the use of any content posted, emailed,
          transmitted, or otherwise made available via the services.
        </li>
      </ul>
    </div>
  );
}

export default Terms;
