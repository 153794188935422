import React, { useState, useEffect } from "react";
import patchnotescss from "../css/Patchnotes.css";

function Patchnotes() {
  const [patchNotes, setPatchNotes] = useState([]);

  useEffect(() => {
    // Fetch the patchnotes data
    fetch("/patchnotes.json")
      .then((response) => response.json())
      .then((data) => setPatchNotes(data.patchNotes))
      .catch((error) => console.error("Error fetching patch notes:", error));
  }, []);

  return (
    <div id="body" className="patchnotes-body">
      <h1 className="patchnotes-title">Patch Notes</h1>
      {patchNotes.map((note) => {
        const { newFeatures, improvements, bugFixes, knownIssues } =
          note.changes;

        // Function to check if all fields are empty or falsy
        const isEmpty = (item) =>
          !item.title || !item.description || !item.details.length;

        // Check if all sections are empty or falsy
        const isNewFeaturesEmpty =
          !newFeatures.length || newFeatures.every(isEmpty);
        const isImprovementsEmpty =
          !improvements.length || improvements.every(isEmpty);
        const isBugFixesEmpty = !bugFixes.length || bugFixes.every(isEmpty);
        const isKnownIssuesEmpty =
          !knownIssues.length || knownIssues.every(isEmpty);

        // Skip rendering the entire note if all sections are empty or falsy
        if (
          isNewFeaturesEmpty &&
          isImprovementsEmpty &&
          isBugFixesEmpty &&
          isKnownIssuesEmpty
        ) {
          return null;
        }

        return (
          <div key={note.version} className="patchnotes-version">
            <h2 className="patchnotes-version-title">Version {note.version}</h2>
            <p className="patchnotes-release-date">
              Release Date: {note.releaseDate}
            </p>

            {!isNewFeaturesEmpty && (
              <div className="patchnotes-section">
                <h3 className="patchnotes-section-title">New Features</h3>
                <ul className="patchnotes-list">
                  {newFeatures
                    .filter(
                      (feature) =>
                        feature.title &&
                        feature.description &&
                        feature.details.length > 0
                    )
                    .map((feature) => (
                      <li key={feature.title} className="patchnotes-list-item">
                        <strong className="patchnotes-item-title">
                          {feature.title}:
                        </strong>{" "}
                        {feature.description}
                        <ul className="patchnotes-sublist">
                          {feature.details.map((detail, index) => (
                            <li key={index} className="patchnotes-sublist-item">
                              {detail}
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              </div>
            )}

            {!isImprovementsEmpty && (
              <div className="patchnotes-section">
                <h3 className="patchnotes-section-title">Improvements</h3>
                <ul className="patchnotes-list">
                  {improvements
                    .filter(
                      (improvement) =>
                        improvement.title &&
                        improvement.description &&
                        improvement.details.length > 0
                    )
                    .map((improvement) => (
                      <li
                        key={improvement.title}
                        className="patchnotes-list-item"
                      >
                        <strong className="patchnotes-item-title">
                          {improvement.title}:
                        </strong>{" "}
                        {improvement.description}
                        <ul className="patchnotes-sublist">
                          {improvement.details.map((detail, index) => (
                            <li key={index} className="patchnotes-sublist-item">
                              {detail}
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              </div>
            )}

            {!isBugFixesEmpty && (
              <div className="patchnotes-section">
                <h3 className="patchnotes-section-title">Bug Fixes</h3>
                <ul className="patchnotes-list">
                  {bugFixes
                    .filter(
                      (bugFix) =>
                        bugFix.title &&
                        bugFix.description &&
                        bugFix.details.length > 0
                    )
                    .map((bugFix) => (
                      <li key={bugFix.title} className="patchnotes-list-item">
                        <strong className="patchnotes-item-title">
                          {bugFix.title}:
                        </strong>{" "}
                        {bugFix.description}
                        <ul className="patchnotes-sublist">
                          {bugFix.details.map((detail, index) => (
                            <li key={index} className="patchnotes-sublist-item">
                              {detail}
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              </div>
            )}

            {!isKnownIssuesEmpty && (
              <div className="patchnotes-section">
                <h3 className="patchnotes-section-title">Known Issues</h3>
                <ul className="patchnotes-list">
                  {knownIssues
                    .filter(
                      (issue) =>
                        issue.title &&
                        issue.description &&
                        issue.details.length > 0
                    )
                    .map((issue) => (
                      <li key={issue.title} className="patchnotes-list-item">
                        <strong className="patchnotes-item-title">
                          {issue.title}:
                        </strong>{" "}
                        {issue.description}
                        <ul className="patchnotes-sublist">
                          {issue.details.map((detail, index) => (
                            <li key={index} className="patchnotes-sublist-item">
                              {detail}
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Patchnotes;
