import InviteCSS from "../css/DiscordInvite.css";

function DiscordInviteBanner() {
  const features = ["24/7 support", "99.9% uptime"];

  const checkmarkSvg = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="checkmark"
    >
      {" "}
       
      <polyline points="20 6 9 17 4 12" />
    </svg>
  );

  return (
    <div className="discord-invite-banner">
      <h1 className="discord-invite-h2">
        Boost Customer Feedback with Our Public Review Bot: Effortless and
        Effective
      </h1>
      <p className="subtitle">
        🚀 Join our Discord now to access exclusive support, updates, and more.
        Your next adventure starts here!
      </p>
      <div className="buttons-banner">
        <a
          href="https://discord.com/oauth2/authorize?client_id=1262102986696556554"
          className="invite-button"
        >
          Add to discord
        </a>
        <a href="https://discord.gg/bFSh2mBPzw" className="add-button">
          Support server
        </a>
      </div>
      <ul className="feature-list">
        {features.map((feature, index) => (
          <li key={index} className="feature-item">
            {checkmarkSvg}
            <span className="feature-text">
              {feature} {/* Added console.log */}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DiscordInviteBanner;
