// src/Routes.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  loader,
} from "react-router-dom";
import Home from "./views/Home";
import Status from "./views/Status";
import Patchnotes from "./views/PatchNotes";
import Terms from "./views/Tos";
import Policy from "./views/Policy";
import Mentions from "./views/Mentions";
import Login from "./views/Login";
import NotFound from "./views/404";
import Maintanance from "./views/maintanance";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/status" element={<Status />} />
      <Route path="/patchnotes" element={<Patchnotes />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Policy />} />
      <Route path="/mentions" element={<Mentions />} />
      <Route path="/login" element={<Login />} />
      <Route path="/maintanance" element={<Maintanance />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
