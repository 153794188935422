import React from "react";
import Greenenergycss from "../css/Greenenergy.css";
import Logoimg from "../assets/Greenenergy.png";
import { useEffect, useRef } from "react";

function GreenEnergy() {
  return (
    <div className="green-energy">
      {/* <img src={Logoimg} alt="Green Energy" /> */}
      <div></div>
      <div className="green-energy-content">
        <h1>Green Energy</h1>
        <p>
          We Beprecise Support making the planet better, that is why we are
          using energy from green energy sources. Our servers run on a 100%
          green energy to reduce emissions and save the planet.
        </p>
      </div>
    </div>
  );
}

export default GreenEnergy;
