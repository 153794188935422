function Mentions() {
  return (
    <div id="body">
      <h1>Mentions</h1>
      <a href="https://lewdhutao.is-a.dev">LewdHutao | Inspiration</a>
    </div>
  );
}

export default Mentions;
