import React, { useState } from "react";
import "../css/Login.css"; // Import the CSS file

function Login() {
  const [isLogin, setIsLogin] = useState(true);

  return (
    <div id="containerlogin">
      {isLogin ? (
        <div id="loginform">
          <h1>Login</h1>
          <form>
            <input type="text" id="username" placeholder="Username" />
            <input type="password" id="password" placeholder="Password" />
            <button type="submit" id="loginbtn">
              Login
            </button>
          </form>
          <p>
            No account?{" "}
            <a href="#" onClick={() => setIsLogin(false)}>
              Register now
            </a>
          </p>
        </div>
      ) : (
        <div id="registerform">
          <h1>Register</h1>
          <form>
            <input type="text" id="regusername" placeholder="Username" />
            <input type="password" id="regpassword" placeholder="Password" />
            <input
              type="password"
              id="regpassword"
              placeholder="Confirm Password"
            />
            <input type="email" id="regemail" placeholder="Email" />
            <button type="submit" id="registerbtn">
              Register
            </button>
          </form>
          <p>
            Already have an account?{" "}
            <a href="#" onClick={() => setIsLogin(true)}>
              Login
            </a>
          </p>
        </div>
      )}
    </div>
  );
}

export default Login;
