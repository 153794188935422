// NotFound.js
import React from "react";
import "../css/404.css"; // Ensure the CSS file is correctly referenced

function NotFound() {
  return (
    <div id="body404">
      <div id="container404">
        <h1>Oops! Page Not Found</h1>
        <p>We're sorry, but the page you're looking for could not be found.</p>
        <p>
          Return to <a href="/">Homepage</a>
        </p>
      </div>
    </div>
  );
}

export default NotFound;
