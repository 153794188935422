function Policy() {
  return (
    <div id="body" className="terms" style={{ marginBottom: "10vh" }}>
      <h1>Privacy Policy</h1>
      <p className="policy">
        BePrecise is committed to protecting your privacy. This Privacy Policy
        explains how your personal information is collected, used, and disclosed
        by BePrecise.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        We collect information you provide directly to us, such as when you
        create an account, request customer support, or otherwise communicate
        with us. The types of information we may collect include your name,
        email address, postal address, phone number, and any other information
        you choose to provide.
      </p>

      <h2>2. Information We Collect Automatically</h2>
      <p className="termslistitem">
        When you access or use our services, we may automatically collect
        information about you, including:
      </p>
      <ul className="termslistitem">
        <li>
          <strong>Log Information:</strong> We log information about your use of
          our services, including the type of browser you use, access times,
          pages viewed, your IP address, and the page you visited before
          navigating to our services.
        </li>
        <li>
          <strong>Device Information:</strong> We may collect information about
          the device you use to access our services, including the hardware
          model, operating system and version, unique device identifiers, and
          mobile network information.
        </li>
        <li>
          <strong>
            Information Collected by Cookies and Other Tracking Technologies:
          </strong>{" "}
          We may use cookies and other tracking technologies to collect
          information about you and your interaction with our services,
          including information about your browsing behavior, purchase behavior,
          and other engagement with our services.
        </li>
      </ul>

      <h2>3. Use of Information</h2>
      <p>We may use the information we collect to:</p>
      <ul className="termslistitem">
        <li>Provide, maintain, and improve our services;</li>
        <li>
          Send you technical notices, updates, security alerts, and support and
          administrative messages;
        </li>
        <li>
          Respond to your comments, questions, and requests, and provide
          customer service;
        </li>
        <li>
          Communicate with you about products, services, offers, promotions, and
          events offered by BePrecise and others, and provide news and
          information we think will be of interest to you;
        </li>
        <li>
          Monitor and analyze trends, usage, and activities in connection with
          our services;
        </li>
        <li>
          Personalize and improve the services and provide advertisements,
          content, or features that match user profiles or interests;
        </li>
        <li>Process and deliver contest entries and rewards;</li>
        <li>
          Link or combine with information we get from others to help understand
          your needs and provide you with better service;
        </li>
        <li>
          Carry out any other purpose described to you at the time the
          information was collected.
        </li>
      </ul>

      <h2>4. Sharing of Information</h2>
      <p>
        We may share information about you as follows or as otherwise described
        in this Privacy Policy:
      </p>
      <ul className="termslistitem">
        <li>
          With vendors, consultants, and other service providers who need access
          to such information to carry out work on our behalf;
        </li>
        <li>
          In response to a request for information if we believe disclosure is
          in accordance with, or required by, any applicable law, regulation, or
          legal process;
        </li>
        <li>
          If we believe your actions are inconsistent with our user agreements
          or policies, or to protect the rights, property, and safety of
          BePrecise or others;
        </li>
        <li>
          In connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company;
        </li>
        <li>With your consent or at your direction.</li>
      </ul>

      <h2>5. Security</h2>
      <p>
        BePrecise takes reasonable measures to help protect information about
        you from loss, theft, misuse, and unauthorized access, disclosure,
        alteration, and destruction.
      </p>

      <h2>6. Data Retention</h2>
      <p>
        We will retain your personal information for the period necessary to
        fulfill the purposes outlined in this Privacy Policy unless a longer
        retention period is required or permitted by law.
      </p>

      <h2>7. Your Choices</h2>
      <p>
        <strong>Account Information:</strong> You may update, correct, or delete
        information about you at any time by logging into your account or
        emailing us at info@beprecise.eu. If you wish to delete or deactivate
        your account, please email us at info@beprecise.eu, but note that we may
        retain certain information as required by law or for legitimate business
        purposes.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at:
      </p>
      <p>Email: info@beprecise.eu</p>
    </div>
  );
}

export default Policy;
