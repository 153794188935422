import React, { useState, useEffect } from "react";
import Logo from "./Logo";
import "../css/Header.css"; // Import your CSS file

function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle the state
  };

  // Close dropdown when clicking outside of it
  const handleClickOutside = (event) => {
    if (isDropdownOpen && !event.target.closest("#containerheader")) {
      setIsDropdownOpen(false);
    }
  };

  // Attach click event listener on component mount
  // and remove it on unmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div id="containerheader">
      <div id="header-content">
        <Logo className="logo-text" />
        {/* Mobile dropdown toggle */}
        <div id="mobile-dropdown-toggle" onClick={toggleDropdown}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white" // Set the stroke to white
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`feather feather-menu ${isDropdownOpen ? "open" : ""}`}
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </div>
        {/* Dropdown menu items */}
        <div id="gridheader" className={isDropdownOpen ? "active" : ""}>
          <a href="/" id="homebtn" onClick={() => setIsDropdownOpen(false)}>
            Home
          </a>
          <a
            href="https://discord.com/oauth2/authorize?client_id=1262102986696556554"
            id="invitebtn"
            onClick={() => setIsDropdownOpen(false)}
          >
            Invite
          </a>
          <a
            href="/status"
            id="statusbtn"
            onClick={() => setIsDropdownOpen(false)}
          >
            Status
          </a>
          <a
            href="/maintenance"
            id="loginbtn-header"
            onClick={() => setIsDropdownOpen(false)}
          >
            Login
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
