function Legal() {
  return (
    <div>
      <h1>Legal</h1>
      <a href="/terms" id="termsbtn">
        Terms and conditions
      </a>
      <br />
      <a href="/privacy" id="policybtn">
        Privacy policy
      </a>
      <br />
      {/* <a href="/mentions" id="mentionsbtn">
        Honorable mentions
      </a> */}
    </div>
  );
}

export default Legal;
